import { Configuration, LogLevel } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        clientId: "e18e39ba-3ec9-4a3b-a0f2-aef5974a59b4",
        authority: "https://docsonlineio.ciamlogin.com",
        redirectUri: `${window.location.origin}/`,
        postLogoutRedirectUri: `${window.location.origin}/`,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false, 
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        break;
                    case LogLevel.Info:
                        console.info(message);
                        break;
                    case LogLevel.Verbose:
                        console.debug(message);
                        break;
                    case LogLevel.Warning:
                        console.warn(message);
                        break;
                }
            },
        },
    },
};

export const protectedResources = {
    docsOnlineApi: {
        endpoint: '/sites',
        scopes: {
            impersonate: ['api://io.docsonline.api/user.impersonation'],
        },
    },
};

export const loginRequest = {
    scopes: [...protectedResources.docsOnlineApi.scopes.impersonate],
};
