import React from 'react';
import { Box, Button, Typography, Link, Tooltip } from '@mui/material';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import LogoutButton from '../components/LogoutButton';
import { Helmet } from 'react-helmet-async';

const SignUp: React.FC = () => {
  const { instance } = useMsal();

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest)
      .catch((error) => console.log(error));
  };

  const handleLoginPopup = () => {
    /**
     * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page 
     * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
     * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations 
     */
    instance.loginPopup({
      ...loginRequest,
      redirectUri: '/auth-callback'
    }).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  };

  const handleLogoutPopup = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: '/',
      account: instance.getActiveAccount(),
    });
  };

  return (
    <>
      <Helmet>
        <title>Sign-up - DocsOnline Creator</title>
        <meta name="description" content="Your low-cost, fully-featured solution for creating and hosting online documentation" />
      </Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', p: 2 }}>
        <img src="/logo-40-40.png" alt="DocsOnline Logo" style={{ marginBottom: '20px' }} />
        <Typography variant="h4" component="h1" gutterBottom>
          Sign up for DocsOnline
        </Typography>
        <Typography variant="body1" component="h2" gutterBottom>
          Your low-cost, fully-featured solution for creating and hosting online documentation
        </Typography>
        <UnauthenticatedTemplate>
          <Tooltip title="Sign up with your work email to get started">
            <Button onClick={handleLoginPopup} variant="contained" color="primary" fullWidth>
              Sign in using Popup
            </Button>
          </Tooltip>
          <Tooltip title="Sign up with your work email to get started">
            <Button onClick={handleLoginRedirect} variant="contained" color="primary" fullWidth>
              Sign in using Redirect
            </Button>
          </Tooltip>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <Typography variant="h4" component="h1" gutterBottom>
            {activeAccount ? activeAccount.name : 'Unknown'}
          </Typography>
          <Tooltip title="Sign up with your work email to get started">
            <Button onClick={handleLogoutPopup} variant="contained" color="primary" fullWidth>
              Sign out using Popup
            </Button>
          </Tooltip>
          <Tooltip title="Sign up with your work email to get started">
            <Button onClick={handleLogoutRedirect} variant="contained" color="primary" fullWidth>
              Sign out using Redirect
            </Button>
          </Tooltip>
          <LogoutButton />
        </AuthenticatedTemplate>
        <Box sx={{ mt: 2 }}>
          <Link href="/">Already have an account? Sign in</Link>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Link href="/terms">Terms of Service</Link> | <Link href="/privacy">Privacy Policy</Link>
        </Box>
      </Box>
    </>
  );
};

export default SignUp;
