import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

const Home: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>Home - DocsOnline Creator</title>
        <meta name="description" content="Your low-cost, fully-featured solution for creating and hosting online documentation" />
      </Helmet>
      <Box sx={{ flexGrow: 1, px: 7, my: 3 }}>
        <Typography variant="h2" sx={{ color: theme.palette.primary.main }}>
          Home Page
        </Typography>
      </Box>
    </>
  );
};

export default Home;
