import React, { useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { IPublicClientApplication, EventType, AccountInfo, EventMessage } from '@azure/msal-browser';
import { createUser, fetchUser } from '../api/api';

interface UserHandlerProps {
    msalInstance: IPublicClientApplication;
}

const UserHandler: React.FC<UserHandlerProps> = ({ msalInstance }) => {
    const { getAccessToken } = useAuth();

    useEffect(() => {
        const handleUserCreation = async (account: AccountInfo) => {
            console.log("Account Info:", account);

            const idTokenClaims = account.idTokenClaims as any;

            const userInfo = {
                externalId: account.homeAccountId,
                displayName: account.name,
                email: idTokenClaims?.email || "Unknown",
                firstName: idTokenClaims?.given_name || "Unknown",
                lastName: idTokenClaims?.family_name || "Unknown",
            };

            console.log("User Info to be sent to API:", userInfo);

            try {
                console.log("Setting active account:", account);
                msalInstance.setActiveAccount(account);

                const token = await getAccessToken();
                console.log("Token acquired:", token);

                const existingUser = await fetchUser(token);
                if (!existingUser) {
                    await createUser(userInfo, token);
                    console.log("User created in the database.");
                } else {
                    console.log("User already exists. No need to create.");
                }
            } catch (error) {
                console.error("Error creating or updating user in database:", error);
            }
        };

        const eventCallback = (event: EventMessage) => {
            console.log("MSAL Event triggered:", event);

            if (
                event.eventType === EventType.LOGIN_SUCCESS ||
                (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.interactionType === "silent")
            ) {
                console.log("LOGIN_SUCCESS or ACQUIRE_TOKEN_SUCCESS event detected.");

                // Extract the account information from the event payload
                const accountInfo = (event.payload as any).account as AccountInfo;

                if (accountInfo) {
                    handleUserCreation(accountInfo);
                } else {
                    console.error("No account information found in event payload.");
                }
            }
        };

        // Register event callback and store the callback ID
        const callbackId = msalInstance.addEventCallback(eventCallback);

        const activeAccount = msalInstance.getActiveAccount();
        if (activeAccount) {
            console.log("Active account found:", activeAccount);
            handleUserCreation(activeAccount);
        }

        // Cleanup function to remove the event callback
        return () => {
            if (callbackId) {
                msalInstance.removeEventCallback(callbackId);
            }
        };
    }, [msalInstance, getAccessToken]);

    return null;
};

export default UserHandler;
