import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - DocsOnline Creator</title>
        <meta name="description" content="Your low-cost, fully-featured solution for creating and hosting online documentation" />
      </Helmet><Box sx={{ p: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to DocsOnline. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [your website URL], including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”).
        </Typography>
        <Typography variant="body1" paragraph>
          Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We may collect information about you in a variety of ways. The information we may collect on the Site includes:
        </Typography>
        <Typography variant="body1" paragraph>
          Personal Data: Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards. You are under no obligation to provide us with personal information of any kind, however your refusal to do so may prevent you from using certain features of the Site.
        </Typography>
        <Typography variant="body1" paragraph>
          Derivative Data: Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.
        </Typography>
        <Typography variant="body1" paragraph>
          Financial Data: Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, [Name of Payment Processor], and you are encouraged to review their privacy policy and contact them directly for responses to your questions.
        </Typography>
        <Typography variant="body1" paragraph>
          Data From Social Networks: User information from social networking sites, such as [social media sites], including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks. If you are using our mobile application, this information may also include the contact information of anyone you invite to use and/or join our mobile application.
        </Typography>
        <Typography variant="body1" paragraph>
          Mobile Device Data: Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the Site from a mobile device.
        </Typography>
        <Typography variant="body1" paragraph>
          Third-Party Data: Information from third parties, such as personal information or network friends, if you connect your account to the third party and grant the Site permission to access this information.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Use of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:
        </Typography>
        <Typography variant="body1" paragraph>
          1. Create and manage your account.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Process your transactions and send you related information, including purchase confirmations and invoices.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Send you marketing and promotional communications.
        </Typography>
        <Typography variant="body1" paragraph>
          4. Fulfill and manage purchases, orders, payments, and other transactions related to the Site.
        </Typography>
        <Typography variant="body1" paragraph>
          5. Improve our services, website, and how we operate our business.
        </Typography>
        <Typography variant="body1" paragraph>
          6. Understand and analyze how you use our site and what products and services are most relevant to you.
        </Typography>
        <Typography variant="body1" paragraph>
          7. Communicate with you about promotions, upcoming events, and other news about products and services we offer.
        </Typography>
        <Typography variant="body1" paragraph>
          8. Enable user-to-user communications.
        </Typography>
        <Typography variant="body1" paragraph>
          9. Compile anonymous statistical data and analysis for use internally or with third parties.
        </Typography>
        <Typography variant="body1" paragraph>
          10. Monitor and analyze usage and trends to improve your experience with the Site.
        </Typography>
        <Typography variant="body1" paragraph>
          11. Request feedback and contact you about your use of the Site.
        </Typography>
        <Typography variant="body1" paragraph>
          12. Resolve disputes and troubleshoot problems.
        </Typography>
        <Typography variant="body1" paragraph>
          13. Respond to product and customer service requests.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Disclosure of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
        </Typography>
        <Typography variant="body1" paragraph>
          By Law or to Protect Rights: If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.
        </Typography>
        <Typography variant="body1" paragraph>
          Third-Party Service Providers: We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
        </Typography>
        <Typography variant="body1" paragraph>
          Marketing Communications: With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.
        </Typography>
        <Typography variant="body1" paragraph>
          Interactions with Other Users: If you interact with other users of the Site, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, liking posts, following blogs.
        </Typography>
        <Typography variant="body1" paragraph>
          Online Postings: When you post comments, contributions, or other content to the Site, your posts may be viewed by all users and may be publicly distributed outside the Site in perpetuity.
        </Typography>
        <Typography variant="body1" paragraph>
          Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
        </Typography>
        <Typography variant="body1" paragraph>
          Affiliates: We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
        </Typography>
        <Typography variant="body1" paragraph>
          Business Partners: We may share your information with our business partners to offer you certain products, services, or promotions.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Security of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Policy for Children
        </Typography>
        <Typography variant="body1" paragraph>
          We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you become aware of any data we have collected from children under age 13, please contact us at [your support email address].
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have questions or comments about this Privacy Policy, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          DocsOnline Inc.
          <br />
          privacy@docsonline.io
        </Typography>
        <Link href="/">Back to Home</Link>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
