import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useOrganization } from '../hooks/useOrganization';

const Organization: React.FC = () => {
  const theme = useTheme();
  const { organizationNavigationItems, selectedOrganizationId } = useOrganization();
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    const selectedOrganization = organizationNavigationItems.find(org => org.id === selectedOrganizationId);
    if (selectedOrganization) {
      setOrganizationName(selectedOrganization.name);
    }
  }, [organizationNavigationItems, selectedOrganizationId]);

  return (
    <>
      <Helmet>
        <title>Organization - DocsOnline Creator</title>
        <meta name="description" content="Manage your organization's settings" />
      </Helmet>
      <Box sx={{ flexGrow: 1, px: 7, my: 3 }}>
        <Typography variant="h2" sx={{ color: theme.palette.primary.main }}>
          Organization Settings
        </Typography>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Manage current organization's profile
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1">
            <strong>Name:</strong> {organizationName}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Organization;
