import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Paper } from '@mui/material';
import { useAuth } from '../hooks/useAuth';
import { useSite } from '../hooks/useSite';
import { fetchSite, updateSiteName, updateSiteSubdomain, deleteSite } from '../api/api';
import SiteHeader from '../components/SiteHeader';
import ConfirmationDialog from '../components/ConfirmationDialog';
import theme from '../theme';
import { Helmet } from 'react-helmet-async';

const SiteSettings: React.FC = () => {
    const navigate = useNavigate();
    const [siteName, setSiteName] = useState<string>('');
    const [subdomain, setSubdomain] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const { getAccessToken } = useAuth();
    const { selectedSiteId, refreshSites } = useSite(); // Get selectedSiteId from context

    useEffect(() => {
        const loadSiteDetails = async () => {
            if (!selectedSiteId) return;
            try {
                const token = await getAccessToken();
                const fetchedSite = await fetchSite(selectedSiteId, token);
                setSiteName(fetchedSite.name);
                setSubdomain(fetchedSite.subdomain || '');
            } catch (error) {
                console.error('Failed to load site:', error);
            }
        };

        loadSiteDetails();
    }, [selectedSiteId, getAccessToken]);

    const handleSave = async () => {
        if (selectedSiteId && siteName && subdomain) {
            setError(null);
            try {
                const token = await getAccessToken();
                await updateSiteName(selectedSiteId, siteName, token);
                await updateSiteSubdomain(selectedSiteId, subdomain, token);
                console.log('Site updated successfully');

                refreshSites().then(() => {
                    navigate(`/sites/${selectedSiteId}/settings`);
                });
            } catch (error: any) {
                const status = error.status;
                const errorMessage = error.body?.error || 'Failed to update site';

                if (status === 409) {
                    setError('Subdomain is already taken');
                } else if (status === 422) {
                    setError('Invalid subdomain format');
                } else if (status === 400) {
                    setError('Invalid request. Please check your input.');
                } else {
                    setError(errorMessage);
                }
                console.error('Failed to update site:', error);
            }
        } else {
            setError('Site name and subdomain are required');
        }
    };

    const handleDelete = async () => {
        try {
            const token = await getAccessToken();
            await deleteSite(selectedSiteId, token);
            console.log('Site deleted successfully');
            refreshSites().then(() => {
                navigate(`/`);
            });
            closeDeleteConfirm();
        } catch (error) {
            console.error('Failed to delete site:', error);
            closeDeleteConfirm();
        }
    };

    const openDeleteConfirm = () => setConfirmDeleteOpen(true);
    const closeDeleteConfirm = () => setConfirmDeleteOpen(false);

    return (
        <>
            <Helmet>
                <title>Site Settings - DocsOnline Creator</title>
                <meta name="description" content="Your low-cost, fully-featured solution for creating and hosting online documentation" />
            </Helmet>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <SiteHeader />
                <Box sx={{ p: 7, borderTop: `1px solid ${theme.palette.divider}` }}>
                    <TextField
                        label="Site Name"
                        value={siteName}
                        onChange={(e) => setSiteName(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ my: 2 }} />
                    <TextField
                        label="Subdomain"
                        value={subdomain}
                        onChange={(e) => setSubdomain(e.target.value)}
                        fullWidth
                        variant="outlined"
                        sx={{ my: 2 }}
                        helperText="Your site will be published at yoursubdomain.docsonline.io" />
                    {error && (
                        <Typography color="error" sx={{ my: 2 }}>
                            {error}
                        </Typography>
                    )}
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                    <Typography variant="h6" sx={{ mt: 4, mb: 1 }}>
                        Danger Zone
                    </Typography>
                    <Paper variant="outlined" sx={{ p: 2, borderColor: '#f8d7da', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography>
                            Deleting this site is permanent and cannot be undone.
                        </Typography>
                        <Button variant="contained" color="error" onClick={openDeleteConfirm}>
                            Delete Site
                        </Button>
                    </Paper>
                </Box>
            </Box>
            <ConfirmationDialog
                open={confirmDeleteOpen}
                title="Confirm Site Deletion"
                message="Are you sure you want to delete this site? This action cannot be undone."
                onClose={closeDeleteConfirm}
                onConfirm={handleDelete}
            />
        </>
    );
};

export default SiteSettings;
