import React from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { Web as WebIcon, Add as AddIcon, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { SiteNavigationItem } from '../lib/definitions';

interface SitesNavigatorProps {
    siteNavigationItems: SiteNavigationItem[];
    selectedSiteId: string;
    isMenuExpanded: boolean;
    error: string | null;
    onSiteClick: () => void;
    onAddSiteClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const SitesNavigator: React.FC<SitesNavigatorProps> = ({
    siteNavigationItems,
    selectedSiteId,
    isMenuExpanded,
    error,
    onSiteClick,
    onAddSiteClick
}) => {
    return (
        <>
            <ListItemButton onClick={onSiteClick}>
                <ListItemIcon>
                    {isMenuExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </ListItemIcon>
                <ListItemText primary="Sites" />
                <IconButton onClick={onAddSiteClick} edge="end" size="small">
                    <AddIcon />
                </IconButton>
            </ListItemButton>
            <Collapse in={isMenuExpanded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {error ? (
                        <ListItemText primary={error} />
                    ) : (
                        siteNavigationItems.map((site) => (
                            <ListItemButton
                                component={Link}
                                to={`/sites/${site.id}`}
                                key={site.id}
                                selected={selectedSiteId === site.id}
                            >
                                <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                                    <WebIcon />
                                </ListItemIcon>
                                <ListItemText primary={site.name} />
                            </ListItemButton>
                        ))
                    )}
                </List>
            </Collapse>
        </>
    );
};

export default SitesNavigator;
