import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';

interface PageSettingsDialogProps {
    isOpen: boolean;
    initialSlug: string;
    onClose: () => void;
    onSave: (slug: string) => void;
}

const PageSettingsDialog: React.FC<PageSettingsDialogProps> = ({
    isOpen,
    initialSlug,
    onClose,
    onSave
}) => {
    const [slug, setSlug] = useState(initialSlug);

    // Update the slug state whenever the initialSlug prop changes
    useEffect(() => {
        setSlug(initialSlug);
    }, [initialSlug]);

    const handleSave = () => {
        onSave(slug);
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Page Settings</DialogTitle>
            <DialogContent>
                <TextField
                    label="Page Slug"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    fullWidth
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PageSettingsDialog;
