import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, List, ListItem, ListItemText, Button } from '@mui/material';
import { fetchUser } from '../api/api';
import { useAuth } from '../hooks/useAuth';
import { useOrganization } from '../hooks/useOrganization';
import { User } from '../lib/definitions';

const AccountOrganizations: React.FC = () => {
  const theme = useTheme();
  const { getAccessToken } = useAuth();
  const { selectedOrganizationId, setSelectedOrganizationId } = useOrganization();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const token = await getAccessToken();
        const userData = await fetchUser(token);
        setUser(userData);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setLoading(false);
      }
    };

    loadUserData();
  }, [getAccessToken]);

  const switchOrganization = (orgId: string) => {
    setSelectedOrganizationId(orgId);
    // Optionally navigate to a relevant page or refresh components as needed
  };

  return (
    <>
      <Helmet>
        <title>Account Organizations - DocsOnline Creator</title>
        <meta name="description" content="View and manage your organization affiliations and roles" />
      </Helmet>
      <Box sx={{ flexGrow: 1, px: 7, my: 3 }}>
        <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 4 }}>
          My Organizations
        </Typography>
        {loading ? (
          <Typography>Loading organizations...</Typography>
        ) : (
          <List>
            {user?.organizationRoles.map(role => (
              <ListItem key={role.organizationId} divider>
                <ListItemText
                  primary={role.organizationName}
                  secondary={`Role: ${role.roleName}`}
                />
                {selectedOrganizationId === role.organizationId ? (
                  <Typography color="primary" sx={{ mr: 2 }}>Current</Typography>
                ) : (
                  <Button color="primary" onClick={() => switchOrganization(role.organizationId)}>
                    Switch
                  </Button>
                )}
              </ListItem>
            ))}

          </List>
        )}
        {user?.organizationRoles.length === 0 && (
          <Typography>No organizations found.</Typography>
        )}
      </Box>
    </>
  );
};

export default AccountOrganizations;
