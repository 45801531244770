import React, { useCallback, useMemo, useState } from 'react';
import { createEditor, BaseEditor, Descendant, Transforms } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import { Box, Button, ButtonGroup } from '@mui/material';

type ParagraphElement = { type: 'paragraph'; children: CustomText[] };
type HeadingElement = { type: 'heading'; level: number; children: CustomText[] };
type ListItemElement = { type: 'list-item'; children: CustomText[] };
type CustomText = { text: string };

type BlockElement = ParagraphElement | HeadingElement | ListItemElement;

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: BlockElement;
    Text: CustomText;
  }
}

interface SlateEditorProps {
  initialValue: Descendant[];
  onSave: (content: Descendant[]) => void;
}

const SlateEditor: React.FC<SlateEditorProps> = ({ initialValue, onSave }) => {
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  
  const [value, setValue] = useState<Descendant[]>(initialValue);

  const renderElement = useCallback((props) => {
    const { attributes, children, element } = props;
    const commonStyles = { marginTop: '15px', marginBottom: '15px' };

    switch (element.type) {
      case 'heading':
        const level = element.level || 1; // Default to level 1 if not defined
        if (level < 1 || level > 6) {
          console.error(`Invalid heading level: ${level}`);
          return (
            <Box component="p" {...attributes} sx={commonStyles}>
              {children}
            </Box>
          );
        }
        return (
          <Box component={`h${level}`} {...attributes} sx={commonStyles}>
            {children}
          </Box>
        );
      case 'list-item':
        return (
          <Box component="li" {...attributes} sx={commonStyles}>
            {children}
          </Box>
        );
      default:
        return (
          <Box component="p" {...attributes} sx={commonStyles}>
            {children}
          </Box>
        );
    }
  }, []);

  const addBlock = (type: 'paragraph' | 'heading' | 'list-item') => {
    let newBlock: BlockElement;
    if (type === 'paragraph') {
      newBlock = { type: 'paragraph', children: [{ text: '' }] };
    } else if (type === 'heading') {
      newBlock = { type: 'heading', level: 1, children: [{ text: '' }] }; // Ensure level is defined
    } else {
      newBlock = { type: 'list-item', children: [{ text: '' }] };
    }
    Transforms.insertNodes(editor, newBlock);
  };

  const handleSave = () => {
    onSave(value);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <ButtonGroup variant="contained" aria-label="outlined primary button group">
        <Button onClick={() => addBlock('paragraph')}>Add Paragraph</Button>
        <Button onClick={() => addBlock('heading')}>Add Heading</Button>
        <Button onClick={() => addBlock('list-item')}>Add List Item</Button>
        <Button onClick={handleSave}>Save</Button>
      </ButtonGroup>
      <Box sx={{ border: 'none', padding: 0 }}>
        <Slate editor={editor} initialValue={value} onChange={newValue => setValue(newValue)}>
          <Editable renderElement={renderElement} style={{ outline: 'none' }} />
        </Slate>
      </Box>
    </Box>
  );
};

export default SlateEditor;
