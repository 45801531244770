import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Button, IconButton, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import LaunchIcon from '@mui/icons-material/Launch';
import { useSite } from '../hooks/useSite';

const SiteHeader: React.FC = () => {
  const { siteNavigationItems, selectedSiteId } = useSite();
  const siteNavigationItem = siteNavigationItems.find(s => s.id === selectedSiteId);

  // Determine protocol and base domain based on the environment
  const isDevelopment = process.env.NODE_ENV === 'development';
  const protocol = isDevelopment ? 'http' : 'https';
  const baseDomain = isDevelopment ? 'localhost:7000' : 'docsonline.io';

  const publishedUrl = siteNavigationItem ? `${protocol}://${siteNavigationItem.subdomain}.${baseDomain}` : '#';

  return (
    <Box sx={{ px: 3, my: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h2" sx={{ color: 'primary.main' }}>
          {siteNavigationItem ? siteNavigationItem.name : 'Loading site...'}
        </Typography>
        {siteNavigationItem && (
          <Tooltip title="Visit your published site" arrow>
            <IconButton
              component="a"
              href={publishedUrl}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ ml: 2 }}
            >
              <LaunchIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {siteNavigationItem && (
        <Button
          component={Link}
          to={`/sites/${siteNavigationItem.id}/settings`}
          startIcon={<SettingsIcon />}
          variant="outlined"
          sx={{ textTransform: 'none' }}
        >
          Site Settings
        </Button>
      )}
    </Box>
  );
};

export default SiteHeader;
