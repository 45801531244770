import React, { createContext, useState, ReactNode, FunctionComponent, useCallback } from 'react';
import { useAuth } from '../hooks/useAuth';
import { fetchOrganizations } from '../api/api';
import { OrganizationNavigationItem } from '../lib/definitions';

interface OrganizationContextType {
    organizationNavigationItems: OrganizationNavigationItem[];
    selectedOrganizationId: string | null;
    error: string | null;
    setSelectedOrganizationId: (id: string | null) => void;
    refreshOrganizations: () => Promise<void>;
}

export const OrganizationContext = createContext<OrganizationContextType | undefined>(undefined);

export const OrganizationProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const [organizationNavigationItems, setOrganizationNavigationItems] = useState<OrganizationNavigationItem[]>([]);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const { getAccessToken } = useAuth();

    // Fetch organizations from the API
    const refreshOrganizations = useCallback(async () => {
        try {
            const token = await getAccessToken();
            const fetchedOrganizations = await fetchOrganizations(token);
            setOrganizationNavigationItems(fetchedOrganizations);
            setError(null);
        } catch (error) {
            console.error('Error fetching sites:', error);
            setError('Error fetching sites');
        }
    }, [getAccessToken]);

    return (
        <OrganizationContext.Provider value={{ organizationNavigationItems, selectedOrganizationId, error, setSelectedOrganizationId, refreshOrganizations }}>
            {children}
        </OrganizationContext.Provider>
    );
};
