import React, { useState } from 'react';
import { List, ListItemButton, ListItemText, IconButton, Menu, MenuItem, Box, ListItemIcon } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { PageNavigationItem } from '../lib/definitions';
import { useTheme } from '@mui/material/styles';

interface PagesNavigatorProps {
    pageNavigationItems: PageNavigationItem[];
    selectedPageId: string;
    onPageClick: (pageNavigationItem: PageNavigationItem) => void;
    onDeletePage: (pageNavigationItem: PageNavigationItem) => void;
    onAddPage: () => void;
}

export const PagesNavigator: React.FC<PagesNavigatorProps> = ({
    pageNavigationItems,
    selectedPageId,
    onPageClick,
    onDeletePage,
    onAddPage
}) => {
    const theme = useTheme();
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; page: PageNavigationItem } | null>(null);

    const handleContextMenu = (event: React.MouseEvent<HTMLButtonElement>, page: PageNavigationItem) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4, page }
                : null,
        );
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    return (
        <Box sx={{ flexShrink: 0, width: '300px', borderRight: `1px solid ${theme.palette.divider}`, p: 2 }}>
            <List>
                {pageNavigationItems.map((page) => (
                    <ListItemButton key={page.id} selected={selectedPageId === page.id} onClick={() => onPageClick(page)} sx={{ my: 1 }} data-testid={`page-item-${page.id}`}>
                        <ListItemText primary={page.name} />
                        <IconButton onClick={(event) => handleContextMenu(event, page)} edge="end">
                            <MoreVertIcon />
                        </IconButton>
                    </ListItemButton>
                ))}
                <ListItemButton onClick={onAddPage} sx={{ my: 1 }}>
                    <ListItemIcon>
                        <AddCircleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Add new page..." />
                </ListItemButton>
            </List>
            <Menu
                open={contextMenu !== null}
                onClose={handleCloseContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
                }
            >
                <MenuItem onClick={() => {
                    onDeletePage(contextMenu.page);
                    handleCloseContextMenu();
                }}>Delete</MenuItem>
            </Menu>
        </Box>
    );
};

export default PagesNavigator;
