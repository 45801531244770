import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Box, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import SideMenu from './components/SideMenu';
import UserHandler from './components/UserHandler';
import Home from './pages/Home';
import Site from './pages/Site';
import Organization from './pages/Organization';
import Account from './pages/Account';
import SignUp from './pages/SignUp';
import SiteSettings from './pages/SiteSettings';
import AccountOrganizations from './pages/AccountOrganizations';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import './App.css';
import { MsalProvider, MsalProviderProps } from '@azure/msal-react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from './authConfig';
import { OrganizationProvider } from './contexts/OrganizationContext';
import { SiteProvider } from './contexts/SiteContext';
import OrganizationMembers from './pages/OrganizationMembers';

interface AppProps extends MsalProviderProps { }

const App: React.FC<AppProps> = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <OrganizationProvider>
            <Router>
              <SiteProvider>
                <UserHandler msalInstance={instance} />
                <Routes>
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/terms" element={<TermsOfService />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route
                    path="/*"
                    element={
                      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
                        <Box sx={{ display: 'flex', height: '100vh' }}>
                          <SideMenu />
                          <Box sx={{ flexGrow: 1, height: '100vh', display: 'flex', flexDirection: 'column', p: 0 }}>
                            <Routes>
                              <Route path="/home" element={<Home />} />
                              <Route path="/sites/:id" element={<Site />} />
                              <Route path="/sites/:id/pages/:pageId" element={<Site />} />
                              <Route path="/sites/:id/settings" element={<SiteSettings />} />
                              <Route path="/organization" element={<Organization />} />
                              <Route path="/organization/members" element={<OrganizationMembers />} />
                              <Route path="/account" element={<Account />} />
                              <Route path="/account/organizations" element={<AccountOrganizations />} />
                              <Route path="*" element={<Home />} />
                            </Routes>
                          </Box>
                        </Box>
                      </MsalAuthenticationTemplate>
                    }
                  />
                </Routes>
              </SiteProvider>
            </Router>
          </OrganizationProvider>
        </ThemeProvider>
      </HelmetProvider>
    </MsalProvider>
  );
};

export default App;
