import React, { createContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { useAuth } from '../hooks/useAuth';
import { fetchSitesForOrganization } from '../api/api';
import { useOrganization } from '../hooks/useOrganization';
import { SiteNavigationItem } from '../lib/definitions';
import { useLocation } from 'react-router-dom';

interface SiteContextType {
    siteNavigationItems: SiteNavigationItem[];
    selectedSiteId: string | null;
    selectedPageId: string | null;
    error: string | null;
    refreshSites: () => Promise<void>;
}

export const SiteContext = createContext<SiteContextType | undefined>(undefined);

export const SiteProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [sites, setSites] = useState<SiteNavigationItem[]>([]);
    const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null);
    const [selectedPageId, setSelectedPageId] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const { getAccessToken } = useAuth();
    const { selectedOrganizationId } = useOrganization();
    const location = useLocation();

    // Helper function to extract site ID and page ID from URL. Match /sites/:id/pages/:pageId
    const extractIdsFromUrl = (pathname: string): { siteId: string | null, pageId: string | null } => {
        const siteMatch = pathname.match(/\/sites\/([^/]+)/);
        const pageMatch = pathname.match(/\/pages\/([^/]+)/);
        const siteId = siteMatch ? siteMatch[1] : null;
        const pageId = pageMatch ? pageMatch[1] : null;
        return { siteId, pageId };
    };

    // Fetch sites from the API
    const refreshSites = useCallback(async () => {
        if (selectedOrganizationId) {
            try {
                const token = await getAccessToken();
                const fetchedSites = await fetchSitesForOrganization(selectedOrganizationId, token);
                setSites(fetchedSites);
                setError(null);
            } catch (error) {
                console.error('Error fetching sites:', error);
                setError('Error fetching sites');
            }
        }
    }, [selectedOrganizationId, getAccessToken]);

    // Update selectedSiteId and selectedPageId when URL changes
    useEffect(() => {
        const { siteId, pageId } = extractIdsFromUrl(location.pathname);
        setSelectedSiteId(siteId);
        setSelectedPageId(pageId);
        console.log(`URL changed: siteId=${siteId}, pageId=${pageId}`);
    }, [location]);

    // Fetch sites initially
    useEffect(() => {
        refreshSites();
    }, [refreshSites]);

    return (
        <SiteContext.Provider value={{ siteNavigationItems: sites, selectedSiteId, selectedPageId, error, refreshSites }}>
            {children}
        </SiteContext.Provider>
    );
};
