import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { useCallback } from 'react';

export const useAuth = () => {
    const { instance, accounts } = useMsal();

    const getAccessToken = useCallback(async () => {
        console.log("Accounts in MSAL:", accounts);

        if (accounts.length === 0) {
            console.error("No user account found.");
            throw new Error("No user account found");
        }

        try {
            console.log("Attempting to acquire token silently for account:", accounts[0]);
            const response = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            });
            console.log("Access Token (Silent):", response.accessToken || "No token retrieved");
            return response.accessToken;
        } catch (silentError) {
            console.error("Silent token acquisition failed:", silentError);

            // Fall back to popup token acquisition
            try {
                const response = await instance.acquireTokenPopup(loginRequest);
                console.log("Access Token (Popup):", response.accessToken || "No token retrieved");
                return response.accessToken;
            } catch (popupError) {
                console.error("Popup token acquisition also failed:", popupError);
                throw new Error("Failed to acquire access token");
            }
        }
    }, [instance, accounts]);

    return {
        getAccessToken,
    };
};
