import React, { useState, useEffect } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { Business as OrganizationIcon } from '@mui/icons-material';
import { useAuth } from '../hooks/useAuth';
import { fetchSitesForOrganization } from '../api/api';
import { useOrganization } from '../hooks/useOrganization';
import { useNavigate } from 'react-router-dom';

const OrganizationSwitcher: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { getAccessToken } = useAuth();
    const { selectedOrganizationId, setSelectedOrganizationId, organizationNavigationItems, refreshOrganizations } = useOrganization();
    const navigate = useNavigate();

    // Fetch organizations when the component loads and select the first organization by default
    useEffect(() => {
        const initializeOrganizations = async () => {
            if (!selectedOrganizationId) {
                await refreshOrganizations();

                if (organizationNavigationItems.length > 0) {
                    const firstOrganizationId = organizationNavigationItems[0].id;
                    setSelectedOrganizationId(firstOrganizationId);

                    // Fetch the sites for the first organization and navigate
                    const token = await getAccessToken();
                    const fetchedSites = await fetchSitesForOrganization(firstOrganizationId, token);
                    if (fetchedSites.length > 0) {
                        navigate(`/sites/${fetchedSites[0].id}`);
                    }
                }
            }
        };

        initializeOrganizations();
    }, [selectedOrganizationId, organizationNavigationItems, setSelectedOrganizationId, navigate, refreshOrganizations, getAccessToken]);

    const handleOrganizationSelect = async (selectedOrganizationId: string) => {
        setSelectedOrganizationId(selectedOrganizationId);
        setAnchorEl(null);

        // Fetch the sites for the selected organization and navigate
        const token = await getAccessToken();
        const fetchedSites = await fetchSitesForOrganization(selectedOrganizationId, token);
        if (fetchedSites.length > 0) {
            navigate(`/sites/${fetchedSites[0].id}`);
        } else {
            console.log('No sites found for the selected organization.');
        }
    };

    const handleOrganizationClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <ListItemButton onClick={handleOrganizationClick}>
                <ListItemIcon>
                    <OrganizationIcon />
                </ListItemIcon>
                <ListItemText primary={selectedOrganizationId ? organizationNavigationItems.find(org => org.id === selectedOrganizationId)?.name : 'Select Organization'} />
            </ListItemButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {organizationNavigationItems.map((org) => (
                    <MenuItem
                        key={org.id}
                        selected={org.id === selectedOrganizationId}
                        onClick={() => handleOrganizationSelect(org.id)}
                    >
                        {org.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default OrganizationSwitcher;
