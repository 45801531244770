import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { useAuth } from '../hooks/useAuth';
import { createSite } from '../api/api';

const SiteDialog = ({ open, onClose, activeOrganizationId, onSiteCreated }) => {
    const [siteName, setSiteName] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const { getAccessToken } = useAuth();

    const handleClose = () => {
        setSiteName('');
        setSubdomain('');
        onClose();
    };

    const handleCreate = async () => {
        if (!activeOrganizationId) {
            alert("No active organization selected.");
            return;
        }
        const token = await getAccessToken();
        try {
            const newSite = await createSite(activeOrganizationId, siteName, subdomain, token);
            handleClose();
            onSiteCreated(newSite.id);
        } catch (error) {
            console.error("Failed to create site:", error);
            alert("Failed to create site. Please try again.");
        }
    };

    useEffect(() => {
        if (open) {
            setSiteName('');
            setSubdomain('');
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create New Site</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Site Name"
                    fullWidth
                    variant="outlined"
                    value={siteName}
                    onChange={(e) => setSiteName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Subdomain"
                    fullWidth
                    variant="outlined"
                    value={subdomain}
                    onChange={(e) => setSubdomain(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate} color="primary">Create</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SiteDialog;
