import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Typography, TextField, Button, IconButton, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SlateEditor from '../components/SlateEditor';
import SiteHeader from '../components/SiteHeader';
import PageSettingsDialog from '../components/PageSettingsDialog';
import PagesNavigator from '../components/PagesNavigator';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { useAuth } from '../hooks/useAuth';
import { useSite } from '../hooks/useSite';
import { fetchPages, fetchPage, updatePageContent, updatePageName, updatePageSettings, createPage, deletePage } from '../api/api';
import { PageModel } from '../lib/definitions';
import { Descendant } from 'slate';

const Site: React.FC = () => {
  const navigate = useNavigate();
  const [pages, setPages] = useState<PageModel[]>([]);
  const [selectedPage, setSelectedPage] = useState<PageModel | null>(null);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [isPageSettingsDialogOpen, setIsPageSettingsDialogOpen] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [pageToDelete, setPageToDelete] = useState<PageModel | null>(null);
  const { getAccessToken } = useAuth();
  const { selectedSiteId, selectedPageId } = useSite();

  useEffect(() => {
    const loadPages = async (siteId: string) => {
      try {
        const token = await getAccessToken();
        const pages = await fetchPages(siteId, token);
        setPages(pages);

        // If no selectedPageId, redirect to the first page
        if (!selectedPageId && pages.length > 0) {
          const firstPage = pages[0];
          navigate(`/sites/${selectedSiteId}/pages/${firstPage.id}`);
        } else if (selectedPageId) {
          const pageFromParams = pages.find(page => page.id === selectedPageId);
          if (pageFromParams) {
            const content = await fetchPage(siteId, pageFromParams.id, token);
            setSelectedPage({ ...pageFromParams, content });
            setPageTitle(pageFromParams.name);
          }
        } else {
          setSelectedPage(null);
          setPageTitle('');
        }
      } catch (error) {
        console.error('Failed to load pages:', error);
      }
    };

    if (selectedSiteId) {
      loadPages(selectedSiteId);
    }
  }, [selectedSiteId, selectedPageId, getAccessToken, navigate]);

  const handlePageClick = async (page: PageModel) => {
    navigate(`/sites/${selectedSiteId}/pages/${page.id}`);
  };

  const handleSave = async (content: Descendant[]) => {
    if (selectedPage) {
      try {
        const token = await getAccessToken();
        await updatePageContent(selectedSiteId!, selectedPage.id, JSON.stringify(content), token);
        await updatePageName(selectedSiteId!, selectedPage.id, pageTitle, token);
        setPages((prevPages) =>
          prevPages.map((page) =>
            page.id === selectedPage.id ? { ...page, name: pageTitle } : page
          )
        );
        setSelectedPage((prevSelectedPage) =>
          prevSelectedPage ? { ...prevSelectedPage, name: pageTitle } : null
        );
        console.log('Page saved successfully');
      } catch (error) {
        console.error('Failed to save page:', error);
      }
    }
  };

  const handlePageSettingsSave = async (newSlug: string) => {
    if (selectedPage) {
      try {
        const token = await getAccessToken();
        await updatePageSettings(selectedSiteId!, selectedPage.id, newSlug, token);

        // Update selectedPage with new slug
        setSelectedPage(prevPage => prevPage ? { ...prevPage, slug: newSlug } : null);

        // Update pages list with new slug
        setPages(prevPages =>
          prevPages.map(page =>
            page.id === selectedPage!.id ? { ...page, slug: newSlug } : page
          )
        );

        console.log('Page settings updated successfully');
      } catch (error) {
        console.error('Failed to update page settings:', error);
      }
    }
  };

  const handleAddPage = async () => {
    try {
      const token = await getAccessToken();
      let num = 1;
      let pageName = `Page ${num}`;

      // Check for existing page names and slugs
      const existingPageNames = pages.map(page => page.name);
      const existingPageSlugs = pages.map(page => page.slug);

      // Generate unique page name
      while (existingPageNames.includes(pageName)) {
        const nameMatch = pageName.match(/(\d+)$/);
        num = nameMatch ? parseInt(nameMatch[1], 10) + 1 : 2;
        pageName = `Page ${num}`;
      }

      let pageSlug = `page-${num}`;

      // Generate unique slug
      let baseSlug = pageSlug;
      let suffix = 1;
      while (existingPageSlugs.includes(pageSlug)) {
        pageSlug = `${baseSlug}-${suffix}`;
        suffix++;
      }

      // Create new page
      const newPage = await createPage(selectedSiteId!, pageName, pageSlug, token);

      setPages(prevPages => [...prevPages, newPage]);
      
      navigate(`/sites/${selectedSiteId}/pages/${newPage.id}`);
    } catch (error) {
      console.error('Failed to add new page:', error);
    }
  };

  const handleDeletePage = (page: PageModel) => {
    setPageToDelete(page);
    setConfirmDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (!pageToDelete) return;
    const token = await getAccessToken();
    await deletePage(selectedSiteId, pageToDelete.id, token);
    setPages(pages.filter(p => p.id !== pageToDelete.id));
    setSelectedPage(null);
    setPageToDelete(null);
    setConfirmDialogOpen(false);
    navigate(`/sites/${selectedSiteId}`);
  };

  const cancelDelete = () => {
    setConfirmDialogOpen(false);
    setPageToDelete(null);
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle ? `${pageTitle} - DocsOnline Creator` : "DocsOnline Creator"}</title>
        <meta name="description" content="Your low-cost, fully-featured solution for creating and hosting online documentation" />
      </Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <SiteHeader />
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <PagesNavigator
            pageNavigationItems={pages}
            selectedPageId={selectedPage ? selectedPage.id : ''}
            onPageClick={handlePageClick}
            onAddPage={handleAddPage}
            onDeletePage={handleDeletePage}
          />
          <Box sx={{ flexGrow: 1, p: 2 }}>
            {selectedPage ? (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <TextField
                    value={pageTitle}
                    onChange={(e) => setPageTitle(e.target.value)}
                    fullWidth
                    variant="outlined"
                    sx={{
                      '.MuiInputBase-input': {
                        fontSize: '2.125rem',
                        fontWeight: 400,
                      },
                    }} />
                  <Tooltip title="Page Settings" arrow>
                    <IconButton onClick={() => setIsPageSettingsDialogOpen(true)} sx={{ ml: 2 }}>
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                {selectedPage.content ? (
                  <SlateEditor key={selectedPage.id} initialValue={selectedPage.content} onSave={handleSave} />
                ) : (
                  <Typography variant="h6">Loading content...</Typography>
                )}
                <Button variant="contained" color="primary" onClick={() => handleSave(selectedPage.content || [])}>
                  Save
                </Button>
                <PageSettingsDialog
                  isOpen={isPageSettingsDialogOpen}
                  onClose={() => setIsPageSettingsDialogOpen(false)}
                  onSave={handlePageSettingsSave}
                  initialSlug={selectedPage.slug}
                />
              </>
            ) : (
              <Typography variant="h6">Select a page to view details</Typography>
            )}
          </Box>
        </Box>
      </Box>
      <ConfirmationDialog
        open={confirmDialogOpen}
        title="Confirm Deletion"
        message={`Are you sure you want to delete "${pageToDelete?.name}"? This action cannot be undone.`}
        onClose={cancelDelete}
        onConfirm={confirmDelete}
      />
    </>
  );
};

export default Site;
