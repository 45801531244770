const API_BASE_URL = process.env.REACT_APP_API_BASE_URI || 'http://localhost:4000';

// Log the API_BASE_URL to verify the environment variable is being picked up
console.log(`REACT_APP_API_BASE_URI: ${process.env.REACT_APP_API_BASE_URI}`);
console.log(`API_BASE_URL: ${API_BASE_URL}`);

const fetchWithAuth = async (url: string, token: string, options: RequestInit = {}) => {
  if (!token) {
    console.error("Access Token is missing or empty");
    throw new Error("Access Token is missing or empty");
  }

  console.log("Sending Access Token: ", token);

  const headers = {
    'Content-Type': 'application/json',
    ...options.headers,
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await fetch(url, { ...options, headers });

    let data;
    try {
      data = await response.json(); // Attempt to parse JSON
    } catch (e) {
      if (!response.ok) {
        // Handle non-JSON errors if the response is not ok
        throw new Error(`Network response was not ok (${response.status}): ${response.statusText}`);
      }
      // If JSON parsing fails but the response is ok, just return a success message (might need adjustment based on API behavior)
      return { message: 'Success', details: 'No additional data provided' };
    }

    if (!response.ok) {
      // Handle HTTP errors with the parsed JSON data
      throw new Error(data.error || `Failed to fetch: ${response.status} ${response.statusText}`);
    }

    return data;
  } catch (error) {
    // Log the error or handle specific cases further if needed
    console.error("Error in fetchWithAuth:", error.message);
    throw new Error(error.message);
  }
};

export const fetchSites = async (token: string) => {
  console.log('API call: fetchSites');
  const data = await fetchWithAuth(`${API_BASE_URL}/sites`, token);
  return data.sites || [];
};

export const fetchSite = async (siteId: string, token: string) => {
  console.log('API call: fetchSite');
  const data = await fetchWithAuth(`${API_BASE_URL}/sites/${siteId}`, token);
  return data;
};

export const fetchPages = async (siteId: string, token: string) => {
  console.log('API call: fetchPages');
  const data = await fetchWithAuth(`${API_BASE_URL}/sites/${siteId}/pages`, token);
  return data.pages || [];
};

export const fetchPage = async (siteId: string, pageId: string, token: string) => {
  console.log('API call: fetchPage');
  const data = await fetchWithAuth(`${API_BASE_URL}/sites/${siteId}/pages/${pageId}`, token);
  return data.content || [];
};

export const updatePageContent = async (siteId: string, pageId: string, content: string, token: string) => {
  console.log('API call: updatePageContent');
  const data = await fetchWithAuth(`${API_BASE_URL}/sites/${siteId}/pages/${pageId}`, token, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ content }),
  });
  return data;
};

export const updatePageName = async (siteId: string, pageId: string, name: string, token: string) => {
  console.log('API call: updatePageName');
  const data = await fetchWithAuth(`${API_BASE_URL}/sites/${siteId}/pages/${pageId}`, token, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name }),
  });
  return data;
};

export const updatePageSettings = async (siteId: string, pageId: string, slug: string, token: string) => {
  console.log('API call: updatePageSettings');
  const data = await fetchWithAuth(`${API_BASE_URL}/sites/${siteId}/pages/${pageId}`, token, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ slug }),
  });
  return data;
};

export const createPage = async (siteId: string, name: string, slug: string, token: string) => {
  console.log('API call: createPage');
  const data = await fetchWithAuth(`${API_BASE_URL}/sites/${siteId}/pages`, token, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name, slug }),
  });
  return data;
};

export const createSite = async (organizationId: string, name: string, subdomain: string, token: string) => {
  console.log('API call: createSite');
  const data = await fetchWithAuth(`${API_BASE_URL}/organizations/${organizationId}/sites`, token, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name, subdomain }),
  });
  return data;
};

export const sendMagicLink = async (email: string, token: string) => {
  console.log('API call: sendMagicLink');
  const data = await fetchWithAuth(`${API_BASE_URL}/send-magic-link`, token, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });
  return data;
};

export const createUser = async (userInfo: any, token: string) => {
  console.log('API call: createUser');
  const data = await fetchWithAuth(`${API_BASE_URL}/users`, token, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userInfo),
  });
  return data;
};

export const fetchUser = async (token: string) => {
  console.log('API call: fetchUser');
  const data = await fetchWithAuth(`${API_BASE_URL}/users/me`, token);
  return data;
};

export const fetchUsersByOrganization = async (organizationId: string, token: string) => {
  console.log('API call: fetchUsersByOrganization');
  const data = await fetchWithAuth(`${API_BASE_URL}/users?organizationId=${organizationId}`, token);
  return data.users || [];
};

export const fetchOrganizations = async (token: string) => {
  console.log('API call: fetchOrganizations');
  const data = await fetchWithAuth(`${API_BASE_URL}/users/me/organizations`, token);
  return data.organizations || [];
};

export const fetchSitesForOrganization = async (organizationId: string, token: string) => {
  console.log('API call: fetchSitesForOrganization');
  const data = await fetchWithAuth(`${API_BASE_URL}/organizations/${organizationId}/sites`, token);
  return data.sites || [];
};

export const updateSiteName = async (siteId: string, name: string, token: string) => {
  console.log('API call: updateSiteName');
  const data = await fetchWithAuth(`${API_BASE_URL}/sites/${siteId}`, token, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name }),
  });
  return data;
};

export const updateSiteSubdomain = async (siteId: string, subdomain: string, token: string) => {
  console.log('API call: updateSiteSubdomain');
  const data = await fetchWithAuth(`${API_BASE_URL}/sites/${siteId}`, token, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ subdomain }),
  });
  return data;
};

export const deletePage = async (siteId: string, pageId: string, token: string) => {
  console.log('API call: deletePage');
  const data = await fetchWithAuth(`${API_BASE_URL}/sites/${siteId}/pages/${pageId}`, token, {
    method: 'DELETE'
  });
  return data;
};

export const deleteSite = async (siteId: string, token: string) => {
  console.log('API call: deleteSite');
  const data = await fetchWithAuth(`${API_BASE_URL}/sites/${siteId}`, token, {
    method: 'DELETE'
  });
  return data;
};
