import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LogoutButton from '../components/LogoutButton';
import { useMsal } from '@azure/msal-react';
import { Helmet } from 'react-helmet-async';

const Account: React.FC = () => {
  const theme = useTheme();
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  return (
    <>
      <Helmet>
        <title>Account - DocsOnline Creator</title>
        <meta name="description" content="Your low-cost, fully-featured solution for creating and hosting online documentation" />
      </Helmet><Box sx={{ flexGrow: 1, px: 7, my: 3 }}>
        <Typography variant="h2" sx={{ color: theme.palette.primary.main }}>
          Account Settings
        </Typography>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Manage your profile and preferences
        </Typography>

        {activeAccount && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="body1">
              <strong>Name:</strong> {activeAccount.name}
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <strong>Email:</strong> {activeAccount.username}
            </Typography>
          </Box>
        )}

        <Box sx={{ mt: 4 }}>
          <LogoutButton />
        </Box>
      </Box>
    </>
  );
};

export default Account;
