import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../hooks/useAuth';
import { useOrganization } from '../hooks/useOrganization';
import { fetchUsersByOrganization } from '../api/api';

const OrganizationMembers: React.FC = () => {
    const theme = useTheme();
    const { getAccessToken } = useAuth();
    const { selectedOrganizationId } = useOrganization();
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadMembers = async () => {
            if (selectedOrganizationId) {
                try {
                    const token = await getAccessToken();
                    const users = await fetchUsersByOrganization(selectedOrganizationId, token);
                    setMembers(users.map(user => {
                        // Extend user object with role name specific to current organization
                        const role = user.organizationRoles.find(role => role.organizationId === selectedOrganizationId);
                        return {
                            ...user,
                            roleName: role ? role.roleName : 'No role assigned'
                        };
                    }));
                } catch (error) {
                    console.error('Failed to fetch organization members:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        loadMembers();
    }, [selectedOrganizationId, getAccessToken]);

    return (
        <>
            <Helmet>
                <title>Organization Members - DocsOnline Creator</title>
                <meta name="description" content="List of all users and their roles within the organization" />
            </Helmet>
            <Box sx={{ flexGrow: 1, px: 7, my: 3 }}>
                <Typography variant="h2" sx={{ color: theme.palette.primary.main }}>
                    Organization Members
                </Typography>
                {loading ? (
                    <Typography>Loading members...</Typography>
                ) : (
                    <List>
                        {members.map((member) => (
                            <ListItem key={member.id} divider>
                                <ListItemText
                                    primary={`${member.firstName} ${member.lastName}`}
                                    secondary={`Role: ${member.roleName}`}
                                />
                            </ListItem>
                        ))}
                        {members.length === 0 && (
                            <Typography>No members found in this organization.</Typography>
                        )}
                    </List>
                )}
            </Box>
        </>
    );
};

export default OrganizationMembers;
