import React, { useState, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Collapse, Menu, MenuItem } from '@mui/material';
import { Home as HomeIcon, Settings as SettingsIcon, ArrowBack as ArrowBackIcon, Person as PersonIcon, KeyboardArrowDown, KeyboardArrowRight, Business } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { useTheme } from '@mui/material/styles';
import { useOrganization } from '../hooks/useOrganization';
import { useSite } from '../hooks/useSite';
import CreateSiteDialog from './CreateSiteDialog';
import OrganizationSwitcher from './OrganizationSwitcher';
import SitesNavigator from './SitesNavigator';

const SideMenu: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSitesMenuExpanded, setIsSitesMenuExpanded] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);
  const { selectedOrganizationId } = useOrganization();
  const { siteNavigationItems, error, selectedSiteId, refreshSites } = useSite();
  const [accountSettingsOpen, setAccountSettingsOpen] = useState(location.pathname.startsWith('/account'));
  const [organizationSettingsOpen, setOrganizationSettingsOpen] = useState(location.pathname.startsWith('/organization'));

  const toggleAccountSettings = () => {
    setAccountSettingsOpen(!accountSettingsOpen);
  };

  const toggleOrganizationSettings = () => {
    setOrganizationSettingsOpen(!organizationSettingsOpen);
  };

  const handleSiteClick = useCallback(() => {
    setIsSitesMenuExpanded(prevOpen => !prevOpen);
  }, []);

  const isSelected = useCallback(
    (siteId: string) => siteId === selectedSiteId,
    [selectedSiteId]
  );

  const handleAddSiteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSiteCreated = (newSiteId: string) => {
    refreshSites().then(() => {
      navigate(`/sites/${newSiteId}`);
    });
  };

  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  const handleCloseSettingsMenu = () => {
    setSettingsAnchorEl(null);
  };

  const navigateTo = (path: string) => {
    handleCloseSettingsMenu();
    navigate(path);
  };

  const isSettingsOrAccountPage = location.pathname.startsWith('/organization') || location.pathname.startsWith('/account');

  return (
    <Box
      sx={{
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        width: `${theme.custom.sideMenuWidth}px`,
        height: '100vh',
        backgroundColor: theme.custom.sideMenuBgColor,
        borderRight: '0.8px solid',
        borderColor: theme.custom.sideMenuBorderColor
      }}
    >
      <Box sx={{ flex: 1 }}>
        <List component="nav" aria-label="top menu items">
          <OrganizationSwitcher />
          <ListItemButton component={Link} to="/home" selected={isSelected('/home')}>
            <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
              <HomeIcon data-testid="HomeIcon" />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
          {isSettingsOrAccountPage ? (
            <>
              <ListItemButton onClick={toggleAccountSettings}>
                <ListItemIcon>
                  {accountSettingsOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </ListItemIcon>
                <ListItemText primary="Account Settings" />
              </ListItemButton>
              <Collapse in={accountSettingsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }} component={Link} to="/account" selected={location.pathname === '/account'}>
                    <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Account" />
                  </ListItemButton>
                  <ListItemButton sx={{ pl: 4 }} component={Link} to="/account/organizations">
                    <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                      <DomainAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Organizations" />
                  </ListItemButton>
                </List>
              </Collapse>
              <ListItemButton onClick={toggleOrganizationSettings}>
                <ListItemIcon>
                  {organizationSettingsOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </ListItemIcon>
                <ListItemText primary="Organization Settings" />
              </ListItemButton>
              <Collapse in={organizationSettingsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }} component={Link} to="/organization" selected={location.pathname === '/organization'}>
                    <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                      <Business />
                    </ListItemIcon>
                    <ListItemText primary="Organization" />
                  </ListItemButton>
                  <ListItemButton sx={{ pl: 4 }} component={Link} to="/organization/members">
                    <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary="Members" />
                  </ListItemButton>
                </List>
              </Collapse>
            </>
          ) : (
            <SitesNavigator
              siteNavigationItems={siteNavigationItems}
              selectedSiteId={selectedSiteId}
              isMenuExpanded={isSitesMenuExpanded}
              error={error}
              onSiteClick={handleSiteClick}
              onAddSiteClick={handleAddSiteClick}
            />
          )}
        </List>
        <CreateSiteDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          activeOrganizationId={selectedOrganizationId}
          onSiteCreated={handleSiteCreated}
        />
      </Box>
      <Box sx={{ mb: 3 }}>
        <List component="nav" aria-label="bottom menu items">
          <ListItemButton component={Link} to={isSettingsOrAccountPage ? "/" : "#"} onClick={isSettingsOrAccountPage ? null : handleSettingsClick}>
            <ListItemIcon>
              {isSettingsOrAccountPage ? <ArrowBackIcon /> : <SettingsIcon />}
            </ListItemIcon>
            <ListItemText primary={isSettingsOrAccountPage ? "Back to Sites" : "Settings"} />
          </ListItemButton>
          <Menu
            anchorEl={settingsAnchorEl}
            open={Boolean(settingsAnchorEl)}
            onClose={handleCloseSettingsMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={() => navigateTo('/account')}>Account Settings</MenuItem>
            <MenuItem onClick={() => navigateTo('/organization')}>Organization Settings</MenuItem>
          </Menu>
        </List>
      </Box>
    </Box>
  );
};

export default SideMenu;
