import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';

const LogoutButton: React.FC = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/"
        });
    };

    return (
        <Button onClick={handleLogout} color="secondary" variant="contained">
            Logout
        </Button>
    );
};

export default LogoutButton;
