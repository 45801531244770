import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import '@fontsource/inter/400.css'; // Regular weight
import '@fontsource/inter/700.css'; // Bold weight

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      sideMenuWidth: React.CSSProperties['width'];
      sideMenuBgColor: React.CSSProperties['color'];
      sideMenuBorderColor: React.CSSProperties['color'];
    };
  }

  interface ThemeOptions {
    custom?: {
      sideMenuWidth?: React.CSSProperties['width'];
      sideMenuBgColor?: React.CSSProperties['color'];
      sideMenuBorderColor?: React.CSSProperties['color'];
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#1e3a8a', // TailwindCSS: blue-900
    },
    secondary: {
      main: '#d1d5db', // TailwindCSS: gray-300
    },
    error: {
      main: red.A400,
    },
    background: {
      default: 'rgb(247, 247, 247)',
    },
  },
  typography: {
    fontFamily: 'Inter, Helvetica Neue, Arial, sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 700, // Bold weight
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700, // Bold weight
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 700, // Bold weight
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400, // Regular weight
    },
    button: {
      textTransform: 'none', // Disable uppercase transformation
    },
  },
  custom: {
    sideMenuWidth: 260,
    sideMenuBgColor: '#dbeafe', // TailwindCSS: blue-100
    sideMenuBorderColor: '#93c5fd', // TailwindCSS: blue-300
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#556cd6',
        },
      },
    },
  },
});

export default theme;
